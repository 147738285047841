import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {TablePagination} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
}));

const PaginationForTable = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };


  const handleRowsPerPageChange = event => {

    setRowsPerPage(event.target.value);
  };

  return(
    <TablePagination
      component="div"
      count={props.tableRows.lengths}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5, 10, 25]}
    />)
}

export default PaginationForTable;