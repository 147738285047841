//Form Component, Takes a class, fieldDetails (objects of field name and label), rows per line in the form
//maps the amount of form rows we need, each in a grid containing that amount of text fields.

import { Grid,  TextField} from "@material-ui/core";
import React from "react";

function TextFieldForm(props){
  let counter = 0;
  let formRows = [];
  const formRowsNeeded = (props.fieldDetails.length/props.fieldsPerRow)
   for(let i=0; i<formRowsNeeded; i++){
    let formRow = (
      <Grid container
            justify="space-around"
            direction="row">
        {props.fieldDetails.slice(counter, counter + props.fieldsPerRow).map(field => {
            return (<TextField
                    key={field.name}
                    className={props.fieldClassName}
                    error={props.hasError(field.name)}
                    helperText={
                      props.hasError(field.name) ? props.formState.errors[field.name][0] : null
                    }
                    label={field.label}
                    name={field.name}
                    onChange={props.onChange}
                    type={field.type}
                    value={props.formState.values[field.name] || ''}
                    variant="outlined"
                    InputLabelProps={field.inputLabelProps || ''}
                  />)
          })}
      </Grid>
    )
     counter += props.fieldsPerRow
     formRows.push(formRow)
  }
   return formRows
}

export default TextFieldForm