//Header (Top Row) Of Table Presentational Component, pass an array of Table Column Header Names
import {TableCell, TableHead, TableRow} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

function TableHeader(props){
  const typographyVariant = props.typographyVariant ? props.typographyVariant : 'h5' //h5 is default if not supplied a typography variant
  return (<TableHead>
            <TableRow>
              {props.columnHeaderNames.map((columnName, i) => {
                return <TableCell key={i} align="center"><Typography variant={typographyVariant}>{columnName}</Typography></TableCell>
              })}
            </TableRow>
          </TableHead>)
}

export default TableHeader