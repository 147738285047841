import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PricingHeader } from './PricingHeader';
import { PricingCardContainer } from './PricingCardContainer';
import { FeatureGrid } from './FeatureGrid';
import { Grid } from '@material-ui/core'
import Button from "../Home/modules/components/Button";
import {Link as RouterLink} from "react-router-dom";
import Container from "@material-ui/core/Container";


// const useStyles = makeStyles(theme => ({
//     animatedItem: {
//         background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
//         backgroundSize: '400% 400%',
//         animation: `$myEffect 15000ms infinite`,
//         width: '100%',
//         height: 'calc(50% + 275px)',
//         position: 'absolute',
//         zIndex: -1,
// },
//     "@keyframes myEffect": {
//         "0%": {
//         backgroundPosition: "0% 50%",
//         },
//         "50%": {
//             backgroundPosition: "100% 50%",
//         },
//         "100%": {
//             backgroundPosition: "0% 50%",
//         }
//     },
//    animatedItem__Container: {
//        borderLeft: '100% solid transparent',
//     }
// }));

const useStyles = makeStyles(theme => ({
    animatedItem__Container: {
        width: '102%',
        position: 'absolute',
        height: 'calc(50% + 100px)',
        zIndex: -1,
        backgroundColor: '#5c9bd4',
    },
    backgroundContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    buttonContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    button: {
        minWidth: 200,
        alignSelf: 'center',
        backgroundColor: '#5c9bd4',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#7CAFDC',
        }
    },
}));


//blue: #5c9bd4 white: '#F4F6F8',
const Pricing = (props) => {
    const classes = useStyles();
    return (
            <div className={classes.backgroundContainer}>
                <div className={classes.animatedItem__Container}>
                </div>
                <PricingHeader />
                <PricingCardContainer />
                <Grid container justify={'center'} className={classes.buttonContainer}>
                    <Grid item>
                        <RouterLink to="/sign-up">
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                className={classes.button}
                            >
                                Register Today
                            </Button>
                        </RouterLink>
                    </Grid>
                </Grid>

            </div>

    )
}

export default Pricing;

