import {
  DELETE_BORROW_ORDER,
  CREATE_BORROW_ORDER,
  EDIT_BORROW_ORDER,
  REQUEST_CURRENT_BORROW_ORDERS,
  REQUEST_HISTORICAL_BORROW_ORDERS,
  REQUEST_CURRENT_BORROW_ORDER_DATES,
  REQUEST_HISTORICAL_BORROW_ORDER_DATES,
  UPDATE_EMPLOYEES_REQUESTED,
} from "../actions/borrowOrder";
import {deleteBorrowDate, findBorrowDate} from "../utility/borrowOrder";

const initialState = {
  currentBorrowOrders: [],
  historicalBorrowOrders: [],
  hasRequestedCurrentBorrowOrders: false,
  hasRequestedHistoricalBorrowOrders: false,
}

const borrowOrderReducer =  (state = initialState, action, ) => {
  switch (action.type) {
    case DELETE_BORROW_ORDER: {
      //todo: move to
      //gets the new min, max date for a borrow order after individual dates are deleted
      let copyBorrowOrders = [...state.currentBorrowOrders]
       //update the dates otherewise
      let objectIndex = copyBorrowOrders.findIndex((order => order.borrowOrderId === action.deleteBorrowOrder.borrowOrderId))
      const orderIndexes = findBorrowDate(copyBorrowOrders, action.deleteBorrowOrder.borrowOrderId, action.deleteBorrowOrder.date)
      copyBorrowOrders = deleteBorrowDate(copyBorrowOrders, orderIndexes.borrowOrderIndex, orderIndexes.borrowDateIndex)
      if((copyBorrowOrders[objectIndex].details).length === 0) {
        copyBorrowOrders = copyBorrowOrders.filter((order => order.borrowOrderId !== action.deleteBorrowOrder.borrowOrderId))
      } else {
        const min = (copyBorrowOrders[objectIndex].details).reduce(function (a, b) { return a.date < b.date ? a : b; }); //finding new min and max dates
        const max = (copyBorrowOrders[objectIndex].details).reduce(function (a, b) { return a.date > b.date ? a : b; });
        copyBorrowOrders[objectIndex]['startDate'] = min.date
        copyBorrowOrders[objectIndex]['endDate'] = max.date
      }
      return {
        ...state,
        currentBorrowOrders: copyBorrowOrders
      }
    }
    case EDIT_BORROW_ORDER: {
      return {
        ...state,
        currentBorrowOrders: action.editBorrowOrder
      }
    }
    case UPDATE_EMPLOYEES_REQUESTED: {
      return {
        ...state,
        currentBorrowOrders: action.updateEmployeesRequested
      }
    }
    case CREATE_BORROW_ORDER: {
      return {
        ...state,
        currentBorrowOrders: [action.createBorrowOrder].concat(state.currentBorrowOrders)
      }
    }
    case REQUEST_CURRENT_BORROW_ORDERS: {
      return {
        ...state,
        currentBorrowOrders: action.currentBorrowOrders,
        hasRequestedCurrentBorrowOrders: true,
      }
    }
    case REQUEST_HISTORICAL_BORROW_ORDERS: {
      return {
        ...state,
        historicalBorrowOrders: action.historicalBorrowOrders,
        hasRequestedHistoricalBorrowOrders: true,
      }
    }
    case REQUEST_CURRENT_BORROW_ORDER_DATES: {
      let copyCurrentBorrowOrderDates = [...state.currentBorrowOrders]
      let objIndex = copyCurrentBorrowOrderDates.findIndex(( //find the index of the Borrow Order so the indiviudal dates can be attached
        order => order.borrowOrderId === action.currentBorrowOrderDates[0].borrowOrderId
      ))
      copyCurrentBorrowOrderDates[objIndex]['details'] = action.currentBorrowOrderDates
      return {
        ...state,
        currentBorrowOrders: copyCurrentBorrowOrderDates
      }
    }
    case REQUEST_HISTORICAL_BORROW_ORDER_DATES: {
      let copyHistoricalBorrowOrderDates = [...state.historicalBorrowOrders]
      let objIndex = copyHistoricalBorrowOrderDates.findIndex(( //find the index of the Borrow Order so the indiviudal dates can be attached
        order => order.borrowOrderId === action.historicalBorrowOrderDates[0].borrowOrderId
      ))
      copyHistoricalBorrowOrderDates[objIndex]['details'] = action.historicalBorrowOrderDates
      return {
        ...state,
        historicalBorrowOrders: copyHistoricalBorrowOrderDates
      }
    }
  default:
    return state
  }
}

export default borrowOrderReducer;
