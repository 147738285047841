import { combineReducers } from 'redux'
import user from './user'
import auth from './auth'
import lendOrderReducer from './lendOrder'
import borrowOrderReducer from "./borrowOrder";
import employeeReducer from "./employee";
import paymentReducer from './payment';
import businessReducer from "./business";



export default combineReducers({
	user,
	auth,
	lendOrderReducer,
	borrowOrderReducer,
	employeeReducer,
	paymentReducer,
	businessReducer,
})
