import {
  deleteEmployeeAPI,
  requestEmployeeDataAPI,
  editEmployeeAPI,
  createEmployeeAPI,
} from "../../api/employee";
import {
  deleteEmployee,
  requestEmployeeData,
  editEmployee,
  createEmployee,
} from "../actions/employee";
import {
  get
} from "lodash";
import {dataValidation} from "../../helpers";

export const requestEmployeeDataAsync = () => {
  return async (dispatch, getState) => {
    try {
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id') //todo: move to utility - change got attributes naming
      if(!businessId){
        throw new Error('No BusinessId')
      }
      const employeeData = await requestEmployeeDataAPI(businessId)
      dispatch(requestEmployeeData(employeeData));
    } catch(e){
      console.error(`Error Requesting Employee Data:`, e)
      throw(e)
    }
  };
}

export const deleteEmployeeAsync = (employeeId) => {
  return async (dispatch, getState) => {
    try{
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id') //todo: move to utility - change got attributes naming
      if(!businessId){
        throw new Error('No BusinessId')
      }
      await deleteEmployeeAPI(employeeId, businessId)
      dispatch(deleteEmployee(employeeId))
    } catch(e){
      console.error(`Error deleting employee: `, e)
      throw(e)
    }
  }
}

export const editEmployeeAsync = (employee) => {
  return async (dispatch, getState) => {
    try{
      dataValidation(employee, ['email', 'firstName', 'lastName', 'phoneNumber', 'street', 'city'])
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id') //todo: move to utility - change got attributes naming
      if(!businessId){
        throw new Error('No BusinessId')
      }
      await editEmployeeAPI(employee, businessId)
      dispatch(editEmployee(employee))
    } catch(e){
      console.error(`Error editing employee: `, e)
      throw(e)
    }
  }
}

export const createEmployeeAsync = (employee) => {
  return async (dispatch, getState) => {
    try {
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id') //todo: move to utility - change got attributes naming
      if(!businessId){
        throw new Error('No BusinessId')
      }
      const createdEmployee = await createEmployeeAPI(employee, businessId) //need the id back of the employee so they can be lent out
      dispatch(createEmployee(createdEmployee))
    } catch(e){
      console.error(`Error creating employee`, e)
      throw(e)
    }
  }
}