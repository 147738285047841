import {
  CREATE_SETUP_INTENT,
  REQUEST_PAYMENT_INFORMATION,
  UPDATE_BANK_ACCOUNT,
  UPDATE_CREDIT_CARD,
} from "../actions/payment";


const initialState = {
  paymentInformation: {
  },
  setupIntent: null,
}

const paymentReducer =  (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PAYMENT_INFORMATION: //boolean for if we have collected payment information from the user (CC/Bank account)
      const paymentInformationDetails = {...state.paymentInformation, ...action.requestPaymentInformation} //new payment details override old
      return {
        ...state,
        paymentInformation: paymentInformationDetails,
      }
    case UPDATE_BANK_ACCOUNT: {
      const paymentInformationDetails = {...state.paymentInformation, payoutMethod: action.updateBankAccount}
      return {
        ...state,
        paymentInformation: paymentInformationDetails,
      }
    }
    case UPDATE_CREDIT_CARD: {
      const paymentInformationDetails = {...state.paymentInformation, paymentMethod: action.updateCreditCard}
      return {
        ...state,
        paymentInformation: paymentInformationDetails,
      }
    }
    case CREATE_SETUP_INTENT: {
      return {
        ...state,
        setupIntent: action.createSetupIntent
      }
    }
    default:
      return state
  }

}

export default paymentReducer