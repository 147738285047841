import React from 'react';
import {Grid} from "@material-ui/core";
import { PricingCard } from "./PricingCard";
import { makeStyles } from "@material-ui/core/styles";
import {AccountBalance, People, MonetizationOn, Autorenew} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    pricingItem: {
        marginTop: 'calc(5%)',
    },
}));

const PricingCardContainer = (props) => {
    const classes = useStyles();
    return (
        <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
         >
            <Grid className={classes.pricingItem} alignItems={'center'} item>
                <PricingCard cardColor={'light'}
                             cardHeader={'Lending'}
                             cardDescription={'Subcontract your employees to other companies'}
                             priceDescription={'on revenue earned when subcontracting out employees'}
                             price={'5%'}
                             pricingLineItems={[
                                 {icon: <AccountBalance color={'primary'}/>,
                                 description: 'No hassle invoicing and automated deposits into your bank account'},
                                 {icon: <People color={'primary'}/>,
                                  description: `Provide income security for your employees getting hours through low periods`},
                                 {icon: <MonetizationOn color={'primary'}/>,
                                  description: 'Maintain flexibility in your workforce and lower your fixed costs'}
                                 ]}/>
            </Grid>
            <Grid className={classes.pricingItem} alignItems={'center'} item>
                <PricingCard cardColor={'dark'}
                             cardHeader={'Borrowing'}
                             cardDescription={'Hire temporary employees from other local companies'}
                             price={'0%'}
                             priceDescription={`We don't charge any fees to companies hiring employees`}
                             pricingLineItems={[
                                 {icon: <People color={'secondary'}/>,
                                 description: `Hire greater quality by accessing under utilized labour from local companies`},
                                 {icon: <Autorenew color={'secondary'}/>, description: 'Optimized matching of an employee with each business'}
                                ]}/>
            </Grid>
        </Grid>
    )
};

export default PricingCardContainer;