import {get} from "lodash";
import {updateUserPasswordAPI, signUpAPI, signInAPI, checkOktaUserStatusAPI} from "../../api/user";
import {userDetails} from "../actions/user";
import {requestBusinessDetails} from "../actions/business";

export const updateUserPasswordAsync = (passwordData) => {
  return async (dispatch, getState) => {
    try {
      await updateUserPasswordAPI(passwordData) // no need to dispatch anything..
    } catch(e){
      console.error(`Error Updating Password Details:`, e)
      throw(e)
    }
  };
}

export const signUpAsync = (userData) => {
  return async (dispatch, getState) => {
    try {
      return await signUpAPI(userData) // no need to dispatch anything..
    } catch(e){
      console.error(`Error Signing Up:`, e)
      throw(e)
    }
  };
}

export const checkOktaUserStatusAsync = (email) => {
  return async (dispatch, getState) => {
    try {
      return await checkOktaUserStatusAPI(email) // no need to dispatch anything
    } catch(e){
      console.error(`Error Checking Okta User Status`, e)
      throw(e)
    }
  };
}

export const signInAsync = (email) => {
  return async (dispatch, getState) => {
    try {
      const signInData = await signInAPI(email)
      if(!signInData.user || !signInData.business){
        const errMsg = `Expected business and user data received: , ${JSON.stringify(signInData)}`
        throw new Error(errMsg);
      }
      dispatch(userDetails(signInData.user))
      dispatch(requestBusinessDetails(signInData.business))
    } catch(e){
      console.error(`Error Signing In:`, e)
      throw(e)
    }
  };
}