export const EMPLOYEE_DATA = 'EMPLOYEE_DATA'
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'


export const editEmployee = editedEmployee => ({type: EDIT_EMPLOYEE, editedEmployee: editedEmployee})
export const requestEmployeeData = employeeData => ({ type: EMPLOYEE_DATA, employeeData: employeeData })
export const deleteEmployee = deleteEmployee => ({ type: DELETE_EMPLOYEE, deleteEmployee: deleteEmployee })
export const createEmployee = createEmployee => ({ type: CREATE_EMPLOYEE, createEmployee })

