import { CircularProgress, Grid, IconButton, Link, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { signUpAsync } from '../../redux/thunks/user';

const schema = {
    firstName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 32,
        },
    },
    lastName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 32,
        },
    },
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64,
        },
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    policy: {
        presence: { message: 'is required' },
        checked: true,
    },
    businessName: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    phoneNumber: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 10,
            minimum: 10,
        },
        format: {
            pattern: '[0-9]+',
            message: 'can only contain numbers',
        },
    },
    street: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    city: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 64,
        },
    },
    state: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    country: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
    metroArea: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
};

const useStyles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    grid: {
        height: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 145,
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formGrid: {
        justifyContent: 'space-around',
    },
    form: {
        // [theme.breakpoints.down('sm')]: { //smaller than 600px width (phones)
        //   paddingLeft: theme.spacing(2),
        //   paddingRight: theme.spacing(2),
        // },
        // paddingLeft: 100,
        // paddingRight: 100,
        // paddingBottom: 125,
        // flexBasis: 850,
    },
    title: {
        marginTop: theme.spacing(3),
    },
    textField: {
        marginTop: theme.spacing(3),
    },
    policy: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    signUpButton: {
        margin: theme.spacing(2, 0),
    },
    spinner: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
    },
});

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formState: {
                isValid: false,
                values: {},
                touched: {},
                errors: {},
            },
            loggingIn: false,
            signUpError: false,
            sessionToken: null,
            signUpErrorText: '',
            redirectToSignIn: false,
            signUpComplete: false,
            oktaUserEmail: '',
            partialSignUpError: false, //denotes whether half a sign up was completed (created Okta but failed with Stripe)
            creatingConnectAccount: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.hasError = this.hasError.bind(this);
        this.setFormState = this.setFormState.bind(this);
    }

    componentDidUpdate() {}

    hasError(field) {
        if (this.state.formState.touched[field] === true && this.state.formState.errors.errors[field]) {
            return true;
        } else {
            return false;
        }
        //formState.touched[field] && formState.errors[field] ? true : false;
    }

    handleChange(event) {
        if (event.target.name !== 'phoneNumber') {
            event.persist();
        }
        this.setFormState(event).then((result) => {
            this.setErrors();
        });
    }

    //setting formstate then setting errors after (get bugs otherwise)
    setFormState(event) {
        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    formState: {
                        ...prevState.formState,
                        values: {
                            ...prevState.formState.values,
                            [event.target.name]:
                                event.target.type === 'checkbox' ? event.target.checked : event.target.value,
                        },
                        touched: {
                            [event.target.name]: true,
                        },
                        errors: {
                            errors: {} || {},
                        },
                        isValid: {
                            isValid: false,
                        },
                    },
                }),
                () => {
                    resolve(true);
                }
            );
        });
    }

    setErrors() {
        const errors = validate(this.state.formState.values, schema);
        this.setState((prevState) => ({
            ...prevState,
            formState: {
                ...prevState.formState,
                values: {
                    ...prevState.formState.values,
                },
                errors: {
                    errors: errors || {},
                },
                isValid: {
                    isValid: errors ? false : true,
                },
            },
        }));
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.content}>
                <div className={classes.contentHeader}>
                    <IconButton onClick={this.handleBack}>
                        <ArrowBackIcon />
                    </IconButton>
                </div>
                <div className={classes.contentBody}>
                    <form
                        className={classes.form}
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        {this.state.loggingIn ? (
                            <Grid container className={classes.spinner}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Typography color="textSecondary" variant="h1">
                                Sign ups are currently closed to the public
                            </Typography>
                        )}
                        <Typography color="textSecondary" variant="body1">
                            Have an account?{' '}
                            <Link component={RouterLink} to="/sign-in" variant="h6">
                                Sign in
                            </Link>
                        </Typography>
                        <Typography color="textSecondary" variant="body1">
                            Need help? reach out at <Link href="mailto:help@comaiko.com">help@comaiko.com</Link>
                        </Typography>
                    </form>
                </div>
            </div>
            // </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        signUp: (userData) => dispatch(signUpAsync(userData)),
    };
}

SignUp.propTypes = {
    history: PropTypes.object,
};

export default withStyles(useStyles)(connect(null, mapDispatchToProps)(withRouter(SignUp)));
