import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import UnsecureRoutes from './routes/UnsecureRoutes';
import { Provider } from 'react-redux';
import store from './store';
const browserHistory = createBrowserHistory();

validate.validators = {
    ...validate.validators,
    ...validators,
};

//secure routes are in the application - unsecure routes are home page, sign in signup

export default class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Router history={browserHistory}>
                        <UnsecureRoutes />
                    </Router>
                </Provider>
            </ThemeProvider>
        );
    }
}
