import React, { Component } from 'react';
import ProductSmokingHero from './modules/homeComponents/ProductSmokingHero';
import AppFooter from './modules/homeComponents/AppFooter';
import ProductHero from './modules/homeComponents/ProductHero';
import ProductValues from './modules/homeComponents/ProductValues';
import ProductHowItWorks from './modules/homeComponents/ProductHowItWorks';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { authenticated: null };
    }

    render() {
        return (
            <React.Fragment>
                <ProductHero />
                <ProductValues />
                <ProductHowItWorks />
                <ProductSmokingHero />
                <AppFooter />
            </React.Fragment>
        );
    }
}
