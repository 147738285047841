import React from 'react'
import {Card, CardActionArea, CardContent, Typography, Grid, Button, Toolbar} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import {AccountBalance, CheckCircle, Event, Forward, Payment, Schedule} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
//todo: align text center/fix iphone layout

const useStyles = makeStyles({
        root: {

        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
        otherText: props => ({
            color: props.otherText.color,
        }),
        topSegment: props => ({
            backgroundColor: props.topSegment.backgroundColor,
        }),
        topSegment__Text: props => ({
            color: props.topSegment__Text.color,
         }),
        middleSegment: props => ({
            backgroundColor: props.middleSegment.backgroundColor,
        }),
        bottomActionArea:  props => ({
            backgroundColor: props.bottomActionArea.backgroundColor,
            height: '100px',
        }),
        subGrid: props => ({
            backgroundColor: props.subGrid.backgroundColor,
            padding: '8px',
        }),
        actionButton: props => ({
                backgroundColor: props.bottomActionArea.backgroundColor,
        }),
});

const navigateToSignUp = () => {
    window.location.href = '/sign-up'
}

const PricingCard = ({cardColor, cardHeader, cardDescription, price, priceDescription, pricingLineItems}) => {
    let cardStyles;
    if(cardColor === 'dark'){
        cardStyles = {
            topSegment__Text: {
              color: '#FFFFFF'
            },
            otherText: {
              color: '#e6ebf1'
            },
            topSegment: {
                backgroundColor: '#2d2d53'
            },
            middleSegment: {
                backgroundColor: '#2d2d53'
            },
            subGrid: {
                backgroundColor: '#2d2d53'
            },
            bottomActionArea: {
                backgroundColor: '#242442'
            },
            bottomActionArea__Text: {
                color: '#f5f5f5'
            }
        }
    } else if(cardColor === 'light') {
        cardStyles = {
            topSegment: {
                backgroundColor: '#ff3366'
            },
            topSegment__Text: {
                color: '#FFFFFF'
            },
            middleSegment: {
                backgroundColor: '#FFFFFF'
            },
            subGrid: {
                backgroundColor: '#ffffff',
            },
            otherText: {
                color: '#111111'
            },
            bottomActionArea: {
                backgroundColor: '#f5f5f5'
            },
            bottomActionArea__Text: {
                color: '#f5f5f5'
            }
        }
    }
    const classes = useStyles(cardStyles)
    return (
        <Card className={classes.root} >
            <CardContent className={classes.topSegment}>
                <Typography className={classes.topSegment__Text} align='center' variant="h1">
                    {cardHeader}
                </Typography>
                <Typography className={classes.topSegment__Text} align='center' variant="h6">
                    {cardDescription}
                </Typography>
            </CardContent>
            <CardContent className={classes.middleSegment}>
                <Typography className={classes.otherText} align='center' variant="h1">
                    {price}
                </Typography>
                <Typography className={classes.otherText}  variant={'subtitle2'} align='center' color={'textSecondary'}>
                    {priceDescription}
                </Typography>
            </CardContent>
            <Divider />
            {pricingLineItems.map(lineItem => {
                return (
                    <Grid container
                          className={classes.subGrid}
                          alignItems="center"
                          justify={'flex-start'}
                          spacing={1}>
                        <Grid item xs={1}>
                            {lineItem.icon}
                        </Grid>
                        <Grid item xs={11}>
                            <Typography
                                className={classes.otherText}
                                variant="h5">
                                {lineItem.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    )
            })}
                <CardActionArea className={classes.bottomActionArea} onClick={navigateToSignUp}>
                    <Grid className={classes.actionButton}
                          container
                          alignItems="center"
                          justify={'center'}
                          spacing={1}>
                        <Grid item>
                            <Typography variant={'h3'} align={'center'} className={classes.otherText}>
                                Get Started
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Forward className={classes.otherText}/>
                        </Grid>
                    </Grid>
                </CardActionArea>
        </Card>
    )
}

export default PricingCard;

//
// {description: 'Accounts are automatically enabled for both lending and borrowing', icon: <CheckCircle />},
// {description: 'Save time and effort on subcontracting - we\'ll do all the heavy lifting', icon: <Schedule />},
// {description: 'Improve income security for your employees getting hours through low periods by subcontracting them out', icon: <Payment />},
// // {description: 'Automatic notifications to employees and organization of their schedules', icon: <Event />},