/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import { withAuth } from '@okta/okta-react';
import InputIcon from '@material-ui/icons/Input';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
}));



const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, dispatch, ...rest } = props;
  const classes = useStyles();
  const logout = async() => {
    props.auth.logout('/home');
  }


  const MainMenuItems = () => {
    return (pages.map(page => (
            <ListItem
              className={classes.item}
              disableGutters
              key={page.title}
            >
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
              >
                <div className={classes.icon}>{page.icon}</div>
                {page.title}
              </Button>
            </ListItem>
          )
        ))
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      <MainMenuItems/>
      <ListItem
        className={classes.item}
        disableGutters
        key={'logout'}
      >
        <Button
          className={classes.button}
          onClick={logout}
        >
          <div className={classes.icon}><InputIcon/></div>
          Sign Out
        </Button>
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};


function mapStateToProps(state) {
  return { user: state.user };
}


export default connect(mapStateToProps)(withAuth(SidebarNav));
