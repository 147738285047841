import React from 'react'
import {
  Snackbar,
} from '@material-ui/core'
import makeStyles from "@material-ui/core/styles/makeStyles";
import { SnackbarContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: '#f44336' //red
  },
  success: {
    backgroundColor: '#4caf50' //green
  },
  warning: {
    backgroundColor: '#ff9800' //orange
  },
  information: {
    backgroundColor: '#2196f3'
  },
}));


export default function SnackbarMessage(props){
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };



  const classes = useStyles()
  //takes props of what type of snackbar wanted: warning, success, error, information
  const snackbarClass = classes[props.snackbarType]
  //override for hiding snackbar (standard is 5 seconds)
  const autoHideDuration = props.autoHideDuration ? props.autoHideDuration : 5000

  return (
      <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleClose}
          open={open}
          autoHideDuration={autoHideDuration}
        >
          <SnackbarContent
            className={snackbarClass}
            message={props.message}
          />
      </Snackbar>
  )
}
