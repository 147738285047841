import {
  DELETE_EMPLOYEE,
  EDIT_EMPLOYEE,
  EMPLOYEE_DATA,
  LOAD_EMPLOYEES,
  CREATE_EMPLOYEE,
} from "../actions/employee";

const initialState = {
  employeeData: null
}

const employeeReducer =  (state = initialState, action) => {
  switch (action.type) {
    case EDIT_EMPLOYEE: {
      let objectIndex = state.employeeData.findIndex((employee => employee.id === action.editedEmployee.id))
      let newEmployeeData = [...state.employeeData] //make copy of current employee data
      newEmployeeData[objectIndex] = action.editedEmployee //change the old employee out for the edited one
      return {
        ...state,
        employeeData: newEmployeeData
      } //
    }
    case DELETE_EMPLOYEE: {
      return {
        ...state,
        employeeData: state.employeeData.filter((employee => employee.id !== action.deleteEmployee)) //gets id of employee to delete
      }
    }
    case CREATE_EMPLOYEE: {
      return {
        ...state,
        employeeData: ([action.createEmployee].concat(state.employeeData)) //when adding new employee, it goes to top
      }
    }
    case EMPLOYEE_DATA: {
    	return {
    		...state,
    		employeeData: action.employeeData//when adding new employee, it goes to top
    	} // employee information (phone number email id etc)
    }
    default:
      return state
  }
}

export default employeeReducer
