import {
	REQUEST_USER_DETAILS,
	INITIAL_LOAD,
} from '../actions/user'

const initialState = {
	userDetails: null,
	initialLoad: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_USER_DETAILS: //email, name, id -
			return {
				...state,
				userDetails: action.userDetails,
			}
		case INITIAL_LOAD: {
			return {
				...state,
				initialLoad: action.initialLoad,
			} // boolean for if inital load of data after login is done
		}
		default:
			return state
	}
}
