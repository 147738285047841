export const CREATE_LEND_ORDER = 'CREATE_LEND_ORDER'
export const DELETE_LEND_ORDER = 'DELETE_LEND_ORDER'
export const EDIT_LEND_ORDER = 'EDIT_LEND_ORDER'
export const REQUEST_CURRENT_LEND_ORDERS = 'REQUEST_CURRENT_LEND_ORDERS'
export const REQUEST_HISTORICAL_LEND_ORDERS = 'REQUEST_HISTORICAL_LEND_ORDERS'

//normal actions
export const createLendOrder = createLendOrder => ({ type: CREATE_LEND_ORDER, newLendOrders: createLendOrder })
export const deleteLendOrder = deleteLendOrder => ({ type: DELETE_LEND_ORDER, deleteLendOrder: deleteLendOrder })
export const editLendOrder = editLendOrder => ({ type: EDIT_LEND_ORDER, editLendOrder: editLendOrder })
export const requestCurrentLendOrders = currentLendOrders => ({
  type: REQUEST_CURRENT_LEND_ORDERS, currentLendOrders: currentLendOrders
})
export const requestHistoricalLendOrders = historicalLendOrders => ({
  type: REQUEST_HISTORICAL_LEND_ORDERS, historicalLendOrders: historicalLendOrders
})
