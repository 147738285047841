//file for retreieving all necessary info with okta, redux attributes ,emplyees etc. - needs to be loaded first
//Main is for all signed in routes - (secure routes)
import React from 'react';
import {
  userDetails,
  initialLoad,

} from '../../../../redux/actions/user';


import { connect } from 'react-redux';
import { withAuth } from '@okta/okta-react';
import axios from 'axios';
import { SnackbarMessage } from "../../../../components";
import { timeout } from "../../../../helpers";
import {get} from "lodash";
import {requestEmployeeDataAsync} from "../../../../redux/thunks/employee";
import {requestPaymentInformationAsync} from '../../../../redux/thunks/payment';
import {signInAsync} from "../../../../redux/thunks/user";


const pathArray = ['/lending', '/borrowing', '/employee-list', '/account', '/stripe-onboarding']

class GetUserDetails extends React.Component {
  constructor(){
    super()
    this.state = {
      redirectToPaymentPortal: false,
      redirectToEmployeesPage: false,
      signInError: false,
      completedRedirect: false
    }
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this)
    this.requestEmployeeInformation = this.requestEmployeeInformation.bind(this)
    this.paymentMethodConfirmation = this.paymentMethodConfirmation.bind(this)
    this.logout = this.logout.bind(this)
    this.navigateToPage = this.navigateToPage.bind(this)
  }


    async componentDidMount() {
      if (this.props.user.userDetails == null) {
        try {
          await this.getCurrentUser()
          await this.handleSignIn()
          await Promise.all([
             this.paymentMethodConfirmation(),
             this.requestEmployeeInformation(),
          ])
          this.props.initialLoad(true)
          this.navigateToPage()
        } catch (error) {
          console.error(error)
          this.handleSignInError()
        }
      }
    }

    async navigateToPage(){
    //todo: desktop?
      if(!this.props.isDesktop){
        return null
      }
      else{
        if(pathArray.includes(this.props.auth._history.location.pathname)){
          this.props.auth._history.push(this.props.auth._history.location.pathname)
        } else if (get(this.props, 'payment.paymentInformation.payoutMethod') ||
          get(this.props, 'payment.paymentInformation.paymentMethod')){
          this.props.auth._history.push('/employee-list')
        } else {
          this.props.auth._history.push('/stripe-onboarding');
        }
      }
    }

    async handleSignInError(){
      /*
      if there is any error on signing in (can't find user in DB, their paymentConfirmation, or load their employees)
      then show an error message and redirect the user back to the sign in page
      */
      try{
        this.setState({
          signInError: true
        })
        await timeout(5000)
        await this.logout()
      } catch(e){
        console.error(e)
      }
    }



    async logout(){
          //from okta, removes user from cache and redirects to home.
          try{
            await this.props.auth.logout('/sign-in')
          } catch(e){
            console.error(e)
          }
      }


    async getCurrentUser(){
      //gets the user from okta
      try{
        const oktaUser = await this.props.auth.getUser()
        this.setState({oktaUser: oktaUser})
      } catch(e){

      }
    }

    async paymentMethodConfirmation(){
      try{
        //userid and business id are currently always the same as create the business id with the user id
        await this.props.requestPaymentInformation()
      } catch(error){
        throw error
      }
    }

    async handleSignIn(){
      //uses okta email after signin to fetch right data from db
        try {
          const email = this.state.oktaUser.email
          await this.props.signIn(email)
        } catch(error){
          console.error('Error Retrieving Initial Sign In Data For User')
          throw error
        }
    }


    async requestEmployeeInformation(){
          try {
            await this.props.requestEmployeeData()
          } catch(error){
            throw error
          }
      }

  render(){
    if(this.state.signInError){
      return(
        (<SnackbarMessage snackbarType={'error'} message={'There was an error signing you in. If this continues to occur \
            please email help@comaiko.com for assistance.'}/>
      ))
    }
    return null;
  }
};



function mapStateToProps(state) {
	return {
		user: state.user,
        payment: state.paymentReducer,
	}
}

function mapDispatchToProps(dispatch) {
	return {
    signIn: email => dispatch(signInAsync(email)),
    requestEmployeeData: () => dispatch(requestEmployeeDataAsync()),
    initialLoad: loadBool => dispatch(initialLoad(loadBool)),
    requestPaymentInformation: () => dispatch(requestPaymentInformationAsync()),
	}
}




export default withAuth(connect(mapStateToProps, mapDispatchToProps)(GetUserDetails));
