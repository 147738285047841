import React from 'react';
import { Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '48px',
        marginTop: '5%',
        color: '#FFFFFF',
    },
    subHeader: {
        marginTop: '2%',
        color: '#FFFFFF'
    },
}));

const PricingHeader = () => {
    const classes = useStyles();
    return (
        <div>
            <Typography className={classes.root} variant={'h1'} align={'center'}>Simple To Understand Pricing</Typography>
            <Typography className={classes.subHeader} variant={'h4'} align={'center'}>Pay as you go. No Monthly Fees.</Typography>
        </div>

    )
};

export default PricingHeader;