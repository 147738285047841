//reusable component of a card with actions, takes actions as a prop, card content as prop
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Card, CardActions, CardContent, CardHeader} from "@material-ui/core";
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'flex-end'
  },
  content: {
    minWidth: 600,
    minHeight: 600,
  },
}));

const CardWithActions = (props) => {

  const classes = useStyles();

  return (
      <Card
        className={classes.root}
      >
        <CardHeader
          action={
            props.CardHeaderActionComponent
          }
          title={props.cardHeaderTitle}
        />
        <PerfectScrollbar>
          <CardContent className={classes.content}>
            {props.CardContentComponent}
          </CardContent>
        </PerfectScrollbar>
        <CardActions className={classes.actions}>
          {props.CardActionsComponent}
        </CardActions>
      </Card>
  );

}

export default CardWithActions;
