import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Schedule, CheckCircle, Payment, Event } from '@material-ui/icons'
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(3),
        alignContent: 'center',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

//Automatic texting employees and organization of schedules
//Optimized matching of an employee with each business
//Increase your revenue by subcontracting
//Save time and effort on subcontracting - we'll do all the heavy lifting
//Accounts are automatically enabled for both



const FeatureGrid = () => {
    const classes = useStyles();
    const features = [
        {description: 'Accounts are automatically enabled for both lending and borrowing employees', icon: <CheckCircle fontSize={'large'}/>},
        {description: 'Save time and effort on subcontracting - we\'ll do all the heavy lifting', icon: <Schedule fontSize={'large'}/>},
        {description: 'Improve income security for your employees getting hours through low periods by subcontracting them out', icon: <Payment fontSize={'large'}/>},
        {description: 'Automatic notifications and directions to employees upon being matched with another company', icon: <Event fontSize={'large'}/>},
    ]


    function FormRow() {
        return (


            <React.Fragment>
                {
                    features.map(feature => {
                        return   <Grid item xs={12} md={6}>
                                    <Grid container alignItems={'center'} spacing={2}>
                                        <Grid item xs={1}>
                                            {feature.icon}
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Typography variant='h4'>
                                                {feature.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                        </Grid>
                    })
                }
            </React.Fragment>
        );
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={1} alignItems={'center'}>
                <Grid container xs={12} spacing={3} alignItems={'center'}>
                    <FormRow />
                </Grid>
            </Grid>
        </div>
    );
}

export default FeatureGrid;