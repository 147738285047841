import axiosInstance from "../../api/axiosInstance";

function createAxiosAuthMiddleware() {
  return ({ getState }) => next => (action) => {
    const { token } = getState().auth;
    axiosInstance.defaults.headers.authorization = token ? `Bearer ${token}` : null;
    return next(action);
  };
}

const axiosAuthMiddleware = createAxiosAuthMiddleware();

export default axiosAuthMiddleware;

