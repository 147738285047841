import React from 'react';
import { withAuth } from '@okta/okta-react';
import {withRouter} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles, useTheme} from "@material-ui/styles";
import {Button, Typography, useMediaQuery, Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  gridItem: {
    marginBottom: theme.spacing(1),
  },
}))


const LoadingPage = props => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const logout = async() => {
    props.auth.logout('/home');
  }

  const classes = useStyles();

  return (
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        {isDesktop ?
          <CircularProgress /> :
          <Grid container direction="column" alignItems={'center'} justify={'center'}>
            <Grid item className={classes.gridItem}>
             <Typography variant={'h5'}>Mobile Coming Soon!</Typography>
            </Grid>
            <Grid item className={classes.gridItem}>
             <Button onClick={logout} color={'primary'} variant={'contained'}>Sign Out</Button>
            </Grid>
          </Grid>
        }
      </div>
    )
}


export default withAuth((withRouter(LoadingPage)));


