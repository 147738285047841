import {
  CREATE_LEND_ORDER,
  DELETE_LEND_ORDER,
  EDIT_LEND_ORDER,
  REQUEST_CURRENT_LEND_ORDERS,
  REQUEST_HISTORICAL_LEND_ORDERS,
} from "../actions/lendOrder";
//https://daveceddia.com/access-redux-store-outside-react/
import { reject } from 'lodash';

const initialState = {
  currentLendOrders: [],
  historicalLendOrders: [],
}




const lendOrderReducer =  (state = initialState, action, ) => {
  switch (action.type) {
    case CREATE_LEND_ORDER: {
      return {
        ...state,
        currentLendOrders: action.newLendOrders
      }
    }
    case REQUEST_CURRENT_LEND_ORDERS: {
      return {
        ...state,
        currentLendOrders: action.currentLendOrders,
      }
    }
    case REQUEST_HISTORICAL_LEND_ORDERS: {
      return {
        ...state,
        historicalLendOrders: action.historicalLendOrders,
      }
    }
    case EDIT_LEND_ORDER: {
      //todo: should this be in a function in the thunk?
      //edit a single lend order
      let copycurrentLendOrders = [...state.currentLendOrders]
      let currentLendOrdersIndex = state.currentLendOrders.findIndex((employee => employee.employeeId === action.editLendOrder.employeeId))
      const editedOrders = copycurrentLendOrders[currentLendOrdersIndex]['details']
      let newIndividualOrderIndex = editedOrders.findIndex((individualOrder => individualOrder.lendId === action.editLendOrder.lendId))
      copycurrentLendOrders[currentLendOrdersIndex]['details'][newIndividualOrderIndex] = action.editLendOrder
      return {
        ...state,
        currentLendOrders: copycurrentLendOrders,
      }
    }
    case DELETE_LEND_ORDER: {
      //todo: should this be in a function in the thunk?
      //Delete a single lend order.
      let copyCurrentLendOrders = [...state.currentLendOrders]
      let objectIndex = state.currentLendOrders.findIndex((employee => employee.employeeId === action.deleteLendOrder.employeeId))
      if(copyCurrentLendOrders[objectIndex]['details'].length === [action.deleteLendOrder].length){
        copyCurrentLendOrders = copyCurrentLendOrders.filter(employee => employee.employeeId !== action.deleteLendOrder.employeeId);
        //if all dates were cancelled remove the employee from state
      } else {
        copyCurrentLendOrders[objectIndex]['details'] = reject(copyCurrentLendOrders[objectIndex]['details'],
          function(el) { return action.deleteLendOrder.lendId === el.lendId });
      }
      return {
        ...state,
        currentLendOrders: copyCurrentLendOrders
      }
    }
    default:
      return state
  }
}

export default lendOrderReducer;




