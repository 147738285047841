import axiosInstance from "./axiosInstance";

export const updateUserPasswordAPI = async (passwordData) => {
    try{
        await axiosInstance.post('api/changeUserPassword', {
            passwordData
        })
    } catch(e){
        console.error(`Error Updating Password`, e)
        throw(e)
    }
}

export const signUpAPI = async (userData) => {
    try {
        const signUpObject = await axiosInstance.post('auth/signup', {
            userData
        })
        return signUpObject.data
    } catch(e){
        console.error(`Error signing up`, e)
        throw(e)
    }
}

export const checkOktaUserStatusAPI = async (email) => {
    try {
        const oktaUserStatus = await axiosInstance.get(`auth/okta-user-status?email=${email}`)
        return oktaUserStatus.data
    } catch(e){
        console.error(`Error checking okta user status`, e)
        throw(e)
    }
}

export const signInAPI = async (email) => {
    try {
        const userInfo = await axiosInstance.post('/auth/signin', {email})
        return userInfo.data
    } catch(e){
        console.error(`Error signing in: `, e)
        throw(e)
    }
}