import { Button, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64,
        },
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128,
        },
    },
};

const useStyles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    grid: {
        height: '100%',
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white,
    },
    bio: {
        color: theme.palette.white,
    },
    contentContainer: {},
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    contentHeader: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    logoImage: {
        marginLeft: theme.spacing(4),
    },
    contentBody: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    form: {
        [theme.breakpoints.down('sm')]: {
            //smaller than 600px width (phones)
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 750,
    },
    title: {
        marginTop: theme.spacing(15),
        alignItems: 'center',
        justifyContent: 'center',
    },
    logoMain: {
        marginTop: theme.spacing(3),
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        marginTop: theme.spacing(3),
    },
    signInButton: {
        margin: theme.spacing(2, 0),
    },
    signInErrorText: {
        marginTop: theme.spacing(2),
    },
    spinner: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        alignItems: 'center',
        justifyContent: 'center',
    },
});

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formState: {
                isValid: false,
                values: {},
                touched: {},
                errors: {},
            },
            loginError: false,
            sessionToken: null,
            loggingIn: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.hasError = this.hasError.bind(this);
    }
    //binding of hasError?//
    handleBack() {
        const { history } = this.props;
        history.push('/home');
    }

    hasError(field) {
        if (this.state.formState.touched[field] === true && this.state.formState.errors.errors[field]) {
            return true;
        } else {
            return false;
        }
    }

    handleChange(event) {
        event.persist();
        this.setFormState(event).then((result) => {
            this.setErrors();
        });
    }

    setFormState(event) {
        //const errors = validate(this.state.formState.values, schema);
        return new Promise((resolve, reject) => {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    formState: {
                        ...prevState.formState,
                        values: {
                            ...prevState.formState.values,
                            [event.target.name]:
                                event.target.type === 'checkbox' ? event.target.checked : event.target.value,
                        },
                        touched: {
                            [event.target.name]: true,
                        },
                        errors: {
                            errors: {} || {},
                        },
                        isValid: {
                            isValid: false,
                        },
                    },
                }),
                () => {
                    resolve(true);
                }
            );
        });
    }

    setErrors() {
        const errors = validate(this.state.formState.values, schema);
        this.setState((prevState) => ({
            ...prevState,
            formState: {
                ...prevState.formState,
                values: {
                    ...prevState.formState.values,
                },
                errors: {
                    errors: errors || {},
                },
                isValid: {
                    isValid: errors ? false : true,
                },
            },
        }));
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({
            errorMessage: 'Password or email is incorrect.',
            loginError: true,
            loggingIn: false,
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid className={classes.grid} container>
                    <Grid className={classes.content} item lg={12} xs={12}>
                        <div className={classes.content}>
                            <div className={classes.contentHeader}>
                                <IconButton onClick={this.handleBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </div>
                            <div className={classes.contentBody}>
                                <form className={classes.form} onSubmit={this.handleSubmit}>
                                    <Grid className={classes.logoMain} container spacing={2}></Grid>
                                    <Typography className={classes.title} variant="h2">
                                        Sign in to Comaiko
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        error={this.hasError('email')}
                                        fullWidth
                                        helperText={
                                            this.hasError('email') ? this.state.formState.errors.errors.email[0] : null
                                        }
                                        label="Email address"
                                        name="email"
                                        onChange={this.handleChange}
                                        type="text"
                                        value={this.state.formState.values.email || ''}
                                        variant="outlined"
                                    />
                                    <TextField
                                        className={classes.textField}
                                        error={this.hasError('password')}
                                        fullWidth
                                        helperText={
                                            this.hasError('password')
                                                ? this.state.formState.errors.errors.password[0]
                                                : null
                                        }
                                        label="Password"
                                        name="password"
                                        onChange={this.handleChange}
                                        type="password"
                                        value={this.state.formState.values.password || ''}
                                        variant="outlined"
                                    />
                                    {this.state.loginError ? (
                                        <div className={classes.signInErrorText}>
                                            <Typography color="error" variant="body1">
                                                {this.state.errorMessage}
                                            </Typography>
                                        </div>
                                    ) : (
                                        <div className={classes.signInErrorText}></div>
                                    )}
                                    {this.state.loggingIn ? (
                                        <Grid container className={classes.spinner}>
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <Button
                                            className={classes.signInButton}
                                            color="primary"
                                            disabled={!this.state.formState.isValid.isValid}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Sign in now
                                        </Button>
                                    )}

                                    <Typography color="textSecondary" variant="body1">
                                        Don't have an account?{' '}
                                        <Link component={RouterLink} to="/sign-up" variant="h6">
                                            Sign up
                                        </Link>
                                    </Typography>
                                </form>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {};
}

SignIn.propTypes = {
    history: PropTypes.object,
};

export default withStyles(useStyles)(connect(null, mapDispatchToProps)(withRouter(SignIn)));
