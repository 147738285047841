import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {  ImplicitCallback } from '@okta/okta-react';
import { RouteWithLayout } from '../components';
import { Minimal as MinimalLayout } from '../layouts';

import {
  SignIn as SignInView,
  SignUp as SignUpView,
  Pricing
} from '../views';
import Home from '../views/Home/Home'

//these are routes that are home/sign in sign up etc that do not need to be password protected
const UnsecureRoutes = () => {
  return (
    <Switch>
        <Redirect exact from="/"  to="/home"/>
        <Route path="/sign-in" render={() => <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/sign-in"
              />} />
        <Route path="/implicit/callback" component={ImplicitCallback} />
        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/sign-up"
        />
        <RouteWithLayout
          component={Home}
          exact
          layout={MinimalLayout}
          path="/home"
        />
        <RouteWithLayout
            component={Pricing}
            exact
            layout={MinimalLayout}
            path="/pricing"
        />
    </Switch>
  );
};

export default UnsecureRoutes;
