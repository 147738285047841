export const requestPaymentInformation = requestPaymentInformation => ({ type: REQUEST_PAYMENT_INFORMATION, requestPaymentInformation })
export const updateBankAccount = updateBankAccount => ({ type: UPDATE_BANK_ACCOUNT, updateBankAccount })
export const updateCreditCard = updateCreditCard => ({ type: UPDATE_CREDIT_CARD, updateCreditCard })
export const createSetupIntent = createSetupIntent => ({type: CREATE_SETUP_INTENT, createSetupIntent})

export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT'
export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD'
export const REQUEST_PAYMENT_INFORMATION = 'REQUEST_PAYMENT_INFORMATION'
export const CREATE_SETUP_INTENT = 'CREATE_SETUP_INTENT'



