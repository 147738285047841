export const CREATE_BORROW_ORDER = 'CREATE_BORROW_ORDER'
export const DELETE_BORROW_ORDER = 'DELETE_BORROW_ORDER'
export const EDIT_BORROW_ORDER = 'EDIT_BORROW_ORDER'
export const REQUEST_CURRENT_BORROW_ORDERS = 'REQUEST_CURRENT_BORROW_ORDERS'
export const REQUEST_HISTORICAL_BORROW_ORDERS = 'REQUEST_HISTORICAL_BORROW_ORDERS'
export const REQUEST_CURRENT_BORROW_ORDER_DATES = 'REQUEST_CURRENT_BORROW_ORDER_DATES'
export const REQUEST_HISTORICAL_BORROW_ORDER_DATES = 'REQUEST_HISTORICAL_BORROW_ORDER_DATES'
export const UPDATE_EMPLOYEES_REQUESTED = 'UPDATE_EMPLOYEES_REQUESTED'

export const createBorrowOrder = createBorrowOrder => ({ type: CREATE_BORROW_ORDER, createBorrowOrder })
export const deleteBorrowOrder = deleteBorrowOrder => ({ type: DELETE_BORROW_ORDER, deleteBorrowOrder: deleteBorrowOrder })
export const editBorrowOrder = editBorrowOrder => ({ type: EDIT_BORROW_ORDER, editBorrowOrder: editBorrowOrder })
export const updateEmployeesRequested = (updateEmployeesRequested) => ({
  type: UPDATE_EMPLOYEES_REQUESTED, updateEmployeesRequested
})

export const requestCurrentBorrowOrders = currentBorrowOrders => ({
  type: REQUEST_CURRENT_BORROW_ORDERS, currentBorrowOrders: currentBorrowOrders
})
export const requestHistoricalBorrowOrders = historicalBorrowOrders => ({
  type: REQUEST_HISTORICAL_BORROW_ORDERS, historicalBorrowOrders: historicalBorrowOrders
})

export const requestCurrentBorrowOrderDates = currentBorrowOrderDates => ({
  type: REQUEST_CURRENT_BORROW_ORDER_DATES, currentBorrowOrderDates: currentBorrowOrderDates
})

export const requestHistoricalBorrowOrderDates = historicalBorrowOrderDates => ({
  type: REQUEST_HISTORICAL_BORROW_ORDER_DATES, historicalBorrowOrderDates
})


