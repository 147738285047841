//utility functions for borrow orders.
import {get, groupBy, map} from "lodash";

export const formatBorrowOrderDates = (borrowOrderDates) => {
  if(!borrowOrderDates){
    const errMsg = 'Borrow Order dates to format not passed in as a parameter'
    throw new Error(errMsg)
  }
  //getting the amount of employees required/assigned for each date
  const dateGroupings = groupBy(borrowOrderDates, 'date')
  return map(dateGroupings, (dateGrouping) => {
    const assignedEmployees = dateGrouping.reduce((acc, cur) => cur.assigned === true ? ++acc : acc, 0);
    return {
      borrowOrderId: dateGrouping[0].borrowOrderId,
      maxPrice: dateGrouping[0].maxPrice,
      date: dateGrouping[0].date,
      assignedEmployees,
      requestedEmployees: dateGrouping.length,
    }
  })
}

export const checkIfAlreadyRequestedOrders = (currentStore, borrowOrderId, reducerPropertyToAccess) => {
  try {
    if(!currentStore || !borrowOrderId || !reducerPropertyToAccess){
      const errMsg = `Missing Required Input: expected currentStore, borrowOrderId and reducerPropertyToAccess`
      throw new Error(errMsg)
    }
    const currentBorrowOrders  = get(currentStore, `borrowOrderReducer.${reducerPropertyToAccess}`)
    if(currentBorrowOrders){
      const order = currentBorrowOrders.find((order => order.borrowOrderId === borrowOrderId))
      if(Array.isArray(order.details)){ //todo: change order details to detail row (issue 246)
        //already requested orders for this borrowOrderId
        return true
      }
    }
  } catch(e){
    throw(e)
  }
}

export const findBorrowDate = (currentBorrowOrders, borrowOrderId, date) => {
  //todo: check parameters
  const borrowOrderIndex = currentBorrowOrders.findIndex(borrowOrder => borrowOrder.borrowOrderId === borrowOrderId)
  const borrowDateIndex = (currentBorrowOrders[borrowOrderIndex]['details']).findIndex(borrowDate => borrowDate.date === date)
  return {borrowOrderIndex, borrowDateIndex}
}

export const deleteBorrowDate = (currentBorrowOrders, borrowOrderIndex, borrowDateIndex) => {
  //todo: check parameters
  //returns the borrow orders with that date deleted.
  currentBorrowOrders[borrowOrderIndex]['details'].splice(borrowDateIndex, 1)
  return currentBorrowOrders
}

export const updateBorrowDate = (currentStore, order, propertyToUpdate, newPropertyValue) => {
  try{
    //todo: check parameters
    let currentBorrowOrders = get(currentStore, `borrowOrderReducer.currentBorrowOrders`)
    const borrowOrderIndex = currentBorrowOrders.findIndex(borrowOrder => borrowOrder.borrowOrderId === order.borrowOrderId)
    const borrowDateIndex = (currentBorrowOrders[borrowOrderIndex]['details']).findIndex(borrowDate => borrowDate.date === order.date)
    currentBorrowOrders[borrowOrderIndex]['details'][borrowDateIndex][propertyToUpdate] = newPropertyValue
    return currentBorrowOrders
  } catch(e){
    console.error(`Error Updating Borrow Date: for order: ${order}, property ${propertyToUpdate}: ${newPropertyValue}`, e)
    throw(e)
  }
}


