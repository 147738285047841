import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import Assignment from '@material-ui/icons/Assignment';
import Mail from '@material-ui/icons/Mail';
import Forward from '@material-ui/icons/Forward';
import { Link as RouterLink } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          How it works
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>1.</div>
                <Assignment
                  fontSize="large"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  Sign up and enter your information for your business and your employees
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>2.</div>
                <Forward
                  fontSize="large"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  Select how many employees you need to hire or want to subcontract out to other companies
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>3.</div>
                <Mail
                  fontSize="large"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  {'When we find a matching order, the employees will be notified where they need to go. '}
                  {'It\'s that easy!'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <RouterLink to="/pricing">
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
          >
            View Pricing
          </Button>
        </RouterLink>
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
