import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import TermsText from './TermsText'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
  },
  termsText: {
    marginLeft: theme.spacing(2),
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TermsAndConditions(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {props.termsLocation === 'sign-up' ?
        (
          <Typography
            className={classes.policyText}
            color="textSecondary"
            variant="body1"
          >
            I have read the{' '}
            <Link
              color="primary"
              onClick={handleClickOpen}
              underline="always"
              variant="h6"
            >
              Terms and Conditions
            </Link>
          </Typography>
        )
         :
        (
          (
            <Link
              color="primary"
              onClick={handleClickOpen}
              underline="hover"
              variant="subtitle2"
            >
              Terms
            </Link>
          )
        )
     }
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <TermsText className={classes.termsText}/>
      </Dialog>
    </div>
  );
}
