import axiosInstance from "./axiosInstance";

export const requestPaymentInformationAPI = async (businessId) => {
  try{
    const paymentInfo = await axiosInstance.get(`businesses/${businessId}/confirm-payment`)
    return paymentInfo.data
  } catch (e){
    console.error(`Error requesting Payment Information:`, e)
    throw(e)
  }
}

export const updateCreditCardAPI = async (paymentMethod, businessId, ) => {
  try{
    await axiosInstance.post(`/businesses/${businessId}/attach-credit-card`, {
      paymentMethod
    })
  } catch(e){
    console.error(`Error attaching credit card`, e)
    throw(e)
  }
}

export const updateBankAccountAPI = async (payoutMethod, businessId) => {
  try {
    await axiosInstance.post(`/businesses/${businessId}/attach-bank-account`, {
      payoutMethod
    })
  } catch(e){
    console.error(`Error Attaching Bank Account`, e)
    throw(e)
  }
}

export const createSetupIntentAPI = async (businessId) => {
  try{
    const setupIntent = await axiosInstance.get(`/businesses/${businessId}/stripe-setup-intent`)
    return setupIntent.data
  } catch(e){
    console.error(`Error Creating Setup Intent for credit card:`, e)
    throw(e)
  }
}


