import axios from "axios";
import axiosInstance from "./axiosInstance";

export const requestEmployeeDataAPI = async (businessId) => {
  try{
    const employees = await axiosInstance.get(`/businesses/${businessId}/employees`)
    return employees.data
  } catch(e) {
    console.error(`Error Requesting Employee Data: `, e)
    throw(e)
  }
}

export const deleteEmployeeAPI = async (employeeId, businessId) => {
  try{
    const employeeData = await axiosInstance.delete(`/businesses/${businessId}/employees/${employeeId}`)
  } catch(e){
    console.error(`Error deleting employee with id: ${employeeId}`, e)
    throw(e)
  }
}

export const editEmployeeAPI = async (employeeToEdit, businessId) => {
  try {
    const employeeId = employeeToEdit.id
    await axiosInstance.put(`/businesses/${businessId}/employees/${employeeId}`, {
      employeeToEdit
    })
  } catch(e){
    console.error(`Error Editing Employee`, e)
    throw(e)
  }
}

export const createEmployeeAPI = async (employee, businessId) => {
  try {
    const newEmployee = await axiosInstance.post(`/businesses/${businessId}/employees`, {
      employee
    })
    return newEmployee.data
  } catch(e){
    console.error(`Error creating employee: `, e)
    throw(e)
  }
}