import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import ShowChart from '@material-ui/icons/ShowChart';
import People from '@material-ui/icons/People';
import AttachMoney from '@material-ui/icons/AttachMoney';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <People fontSize="large"/>
              <Typography variant="h6" className={classes.title}>
                Borrow Labourers
              </Typography>
              <Typography align="center" variant="h5">
                {'If you need extra general labourers for a job, you can '}
                {'easily hire from other local landscaping and construction companies'}
                {' by creating an order'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <AttachMoney fontSize="large"/>
              <Typography variant="h6" className={classes.title}>
                Lend Labourers
              </Typography>
              <Typography align="center" variant="h5">
                {'If you have no work available for your employees '}
                {'you can subcontract them to other companies and earn for your business '}
                {'and for your employees'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <ShowChart fontSize="large"/>
              <Typography variant="h6" className={classes.title}>
                Raise your profits
              </Typography>
              <Typography align="center" variant="h5">
                {'Improve your management of your workforce. '}
                {' Earn revenue for yourself and your employees. Savings and benefits for everyone'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
