import {
  REQUEST_BUSINESS_DETAILS,
} from "../actions/business";

const initialState = {
  businessDetails: {
    name: '',
    phoneNumber: '',
    email: '',
    street: '',
    city: '',
    state: '',
    country: '',
  }
}

const businessReducer =  (state = initialState, action, ) => {
  switch (action.type) {
    case REQUEST_BUSINESS_DETAILS: {
      return {
        businessDetails: action.requestBusinessDetails
      }
    }
    default:
      return state
  }
}

export default businessReducer;