import {
  createSetupIntent,
  requestPaymentInformation,
  updateBankAccount,
  updateCreditCard,
} from "../actions/payment";
import {
  requestPaymentInformationAPI,
  updateCreditCardAPI,
  updateBankAccountAPI, createSetupIntentAPI,
} from "../../api/payment";
import {get} from "lodash";

export const requestPaymentInformationAsync = () => {
  return async (dispatch, getState) => {
    try {
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id')
      if(!businessId){
        throw new Error('No BusinessId')
      }
      const paymentInformation = await requestPaymentInformationAPI(businessId)
      dispatch(requestPaymentInformation(paymentInformation));
    } catch(e){
      console.error(`Error Requesting Payment Information:`, e)
      throw(e)
    }
  };
}

export const updateCreditCardAsync = (paymentMethod) => {
  return async (dispatch, getState) => {
    try {
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id')
      if(!businessId){
        throw new Error('No BusinessId')
      }
      await updateCreditCardAPI(paymentMethod, businessId)
      dispatch(updateCreditCard(true));
    } catch(e){
      console.error(`Error Attaching Credit Card:`, e)
      throw(e)
    }
  };
}

export const updateBankAccountAsync = (paymentMethod) => {
  return async (dispatch, getState) => {
    try {
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id')
      if(!businessId){
        throw new Error('No BusinessId')
      }
      await updateBankAccountAPI(paymentMethod, businessId)
      dispatch(updateBankAccount(true));
    } catch(e){
      console.error(`Error Attaching Bank Account:`, e)
      throw(e)
    }
  };
}

export const createSetupIntentAsync = () => {
  return async (dispatch, getState) => {
    try {
      const currentStore = getState()
      const businessId  = get(currentStore, 'businessReducer.businessDetails.id')
      if(!businessId){
        throw new Error('No BusinessId')
      }
      const setupIntent = await createSetupIntentAPI(businessId)
      dispatch(createSetupIntent(setupIntent));
    } catch(e){
      console.error(`Error Attaching Credit Card:`, e)
      throw(e)
    }
  };
}
