import {
  SET_AUTH,
} from "../actions/auth";
//https://daveceddia.com/access-redux-store-outside-react/
//https://stackoverflow.com/questions/38460949/what-is-the-best-way-to-access-redux-store-outside-a-react-component

const initialState = {
  loading: true,
  user: null,
  token: undefined,
}




const authReducer =  (state = initialState, action, ) => {
  switch (action.type) {
    case SET_AUTH: {
      return {
        ...action.setAuth
      }
    }
    default:
      return state
  }
}

export default authReducer;